import * as CommunicationStatus from 'src/constants/communication-status'
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['dialer']),

    isCallAdded () {
      return this.dialer.communication &&
        this.dialer.communication.legc_uuid &&
        this.dialer.communication.legc_status === CommunicationStatus.STATUS_INPROGRESS_NEW &&
        !this.dialer.communication.in_cold_transfer &&
        this.dialer.call.call_sid !== this.dialer.communication.legc_uuid &&
        (!this.dialer.communication.legz_uuid || this.dialer.call.call_sid !== this.dialer.communication.legz_uuid)
    }
  }
}
